export default {
  path: '/portal',
  name: 'Portal',
  redirect: '/portal/home',
  component: () => import('@/components/layouts/internal/InteralLayout.vue'),
  meta: {
    title: 'Dashboard',
    menu: 1,
    icon: 'bar-chart-fill',
    auth: true,
  },
  children: [
    {
      path: 'home',
      name: 'InternalHome',
      component: () => import('@/views/internal/home/Home.vue'),
      meta: {
        auth: true,
      },
    },

    /** Mi Perfil */
    {
      path: 'my_profile',
      name: 'MyProfile',
      component: () =>
        import('@/components/layouts/internal/InternalMyProfileLayout.vue'),
      meta: {
        auth: true,
      },
      redirect: { name: 'PersonalData' }, // Default route
      children: [
        {
          path: 'personal',
          name: 'PersonalData',
          component: () => import('@/views/internal/my_profile/MyProfilePersonal.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: 'professional',
          name: 'ProfessionalData',
          component: () =>
            import('@/views/internal/my_profile/MyProfileProfessional.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: 'password',
          name: 'RestorePassword',
          component: () =>
            import('@/views/internal/my_profile/MyProfileRestorePassword.vue'),
          meta: {
            auth: true,
          },
        },
      ],
    },

    /** Vista Solicitudes (Paciente) */
    {
      path: 'recipes',
      name: 'Recipes',
      component: () => import('@/views/internal/recipe/RecipeList.vue'),
      meta: {
        auth: true,
      },
    },

    /** Vista Solicitudes (Paciente) */
    {
      path: 'parent_charge',
      name: 'ParentChargeLayout',
      redirect: '/portal/parent_charge/list',
      component: () => import('@/components/layouts/BlankLayout.vue'),
      meta: {
        auth: true,
      },
      children: [
        {
          path: 'list',
          name: 'ParentCharges',
          component: () =>
            import('@/views/internal/parent_charge/ParentChargeList.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: ':parent_charge_id/:method',
          name: 'ParentChargeForm',
          component: () =>
            import('@/views/internal/parent_charge/ParentChargeForm.vue'),
          meta: {
            auth: true,
          },
        },
      ],
    },

    /** Vista Pacientes (Doctor) */
    {
      path: 'patients',
      name: 'PatientLayout',
      redirect: '/portal/patients/list',
      component: () => import('@/components/layouts/BlankLayout.vue'),
      meta: {
        auth: true,
      },
      children: [
        {
          path: 'list',
          name: 'Patients',
          component: () => import('@/views/internal/patient/PatientList.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: 'new/:dni',
          name: 'PatientAddView',
          component: () => import('@/views/internal/patient/PatientAddView.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: 'edit/:patient_id',
          name: 'PatientEditView',
          component: () => import('@/views/internal/patient/PatientEditView.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: ':id/history',
          name: 'PatientHistory',
          component: () => import('@/views/internal/patient/PatientHistory.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: ':patient_id/request/:request_id/:method',
          name: 'DoctorRequestForm',
          component: () => import('@/views/internal/request/RequestForm.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: ':patient_id/request/:request_id/:method/notes',
          name: 'DoctorRequestFormNotes',
          component: () => import('@/views/internal/request/RequestFormNote.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: ':patient_id/request/:request_id/:method/document/:action/:prescription_id/',
          name: 'DoctorRequestFormDocument',
          component: () => import('@/views/internal/request/RequestFormDocument.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: ':patient_id/request/:request_id/:method/send',
          name: 'DoctorRequestFormSend',
          component: () => import('@/views/internal/request/RequestFormSend.vue'),
          meta: {
            auth: true,
          },
        },
      ],
    },
  ],
}
