import axios from 'axios'
import config from '@/config'
import store from '@/store'
import { VueAxios } from './axios'
import { useStorage } from 'vue3-storage'

// Constants
import { AUTH_KEY } from '@/constants/auth.constants'

const service = axios.create({
  baseURL: config.urlApi,
  contentType: 'application/json',
  timeout: 30000,
})

// const VALID_SERVICES = [
//   '/api/users/user/',
//   '/api/auth/login/'
// ]

const err = error => {
  if (
    error.response &&
    error.response.status === 401 &&
    error.request &&
    (error.request.responseURL.indexOf('/api/users/user/') <= -1 ||
      error.request.responseURL.indexOf('/api/auth/login/') <= -1)
  ) {
    store.dispatch('auth/SignOut').finally(() => {
      window.location.href = '/login'
    })
  }
  return Promise.reject(error)
}

// request interceptor
// eslint-disable-next-line no-shadow
service.interceptors.request.use(config => {
  const storage = useStorage()
  const key = storage.getStorageSync(AUTH_KEY)
  if (key) {
    config.headers['Authorization'] = `Token ${key}`
  }
  return config
}, err)

service.interceptors.response.use(response => {
  return response
}, err)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  },
}

export { installer as VueAxios, service as axios }
