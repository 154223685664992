const request = {
  namespaced: true,

  state: {
    tokenDoctor: null,
    selectedUser: {},
    validSession: false,
  },

  getters: {
    tokenDoctor: state => state.tokenDoctor,
    selectedUser: state => state.selectedUser,
    validSession: state => state.validSession,
  },

  mutations: {
    SET_TOKEN_DOCTOR(state, value) {
      state.tokenDoctor = value
    },

    SET_SELECTED_USER(state, value) {
      state.selectedUser = value
    },

    SET_VALID_SESSION(state, value) {
      state.validSession = value
    },

    CLEAR_STORE(state) {
      state.tokenDoctor = null
      state.selectedUser = null
      state.validSession = false
    },
  },

  actions: {
    ClearRequestStore({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}

export default request
