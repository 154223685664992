import moment from 'moment-timezone'
import _ from 'lodash'
import { months } from '@/constants/date.constant'
import { rutFilter } from '@/utils/dniUtil'

export async function loadFilter(vueApp) {
  vueApp.config.globalProperties.$filters = {
    /** Date */
    dayjs(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
      return moment(dataStr).tz('UTC').format(pattern)
    },

    datetime(dataStr, pattern = 'DD/MM/YYYY HH:mm:ss') {
      return moment(dataStr).tz('UTC').format(pattern)
    },

    monthFromYYYYMM(dataStr, pattern = 'MMMM YYYY') {
      return moment(dataStr, 'YYYYMM').tz('UTC').format(pattern)
    },

    date(dataStr, pattern = 'DD-MM-YYYY') {
      if (!dataStr) return 'Sin fecha'
      if (dataStr === 'Por definir') return 'Por Definir'
      return moment(dataStr).tz('UTC').format(pattern)
    },

    month(value) {
      const found = _.find(months, month => month.key === value)
      return found ? found.value : 'No definido'
    },

    /** DNI */
    dni(value) {
      if (value) return rutFilter(value)
      return ''
    },

    /** Currency */
    currency(value) {
      if (!value) {
        return '0'
      }
      const intPartFormat = parseInt(value)
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, '$1.')
      return `$${intPartFormat}`
    },
  }
}
