// Services
import communeServices from '@/services/demography/commune.service'

const commune = {
  namespaced: true,

  state: {
    communes: [],
  },

  getters: {
    communes: state => state.communes,
  },

  mutations: {
    SET_COMMUNES: (state, values) => {
      state.communes = values
    },

    CLEAR_STORE: state => {
      state.communes = []
    },
  },

  actions: {
    GetCommunes({ commit, state }) {
      if (state.communes.length > 0) {
        return new Promise(resolve => {
          resolve(this.state.communes)
        })
      }
      return new Promise((resolve, reject) => {
        communeServices
          .index()
          .then(response => {
            commit('SET_COMMUNES', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    ClearCommunes({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}

export default commune
