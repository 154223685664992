const app = {
  namespaced: true,

  state: {
    showMenu: true,
    windowsWidth: '',
    windowsHeight: '',
    sizes: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
      xl: 2560,
      xll: 4096,
    },
    lastTimeEmailSent: null,
    minutesTimeOut: 3,
  },

  getters: {
    showMenu: state => state.showMenu,
    windowsWidth: state => state.windowsWidth,
    windowsHeight: state => state.windowsHeight,
    sizes: state => state.sizes,
    modalWidth: state => () => {
      if (state.windowsWidth >= state.sizes.lg) {
        return '50%'
      } else if (state.windowsWidth >= state.sizes.md) {
        return '75%'
      } else {
        return '100%'
      }
    },

    availableToSendEmail: state => () => {
      if (state.lastTimeEmailSent) {
        const now = Date.now()
        const diff = now - state.lastTimeEmailSent

        // (60 * 1000) * X = X minutes
        const minutesTimeOut = 60 * 1000 * state.minutesTimeOut
        if (diff < minutesTimeOut) return false
        return true
      } else {
        return true
      }
    },

    minutesTimeOut: state => state.minutesTimeOut,
  },

  mutations: {
    SET_SHOW_MENU(state, value) {
      state.showMenu = value
    },

    CLEAR_STORE(state) {
      state.showMenu = true
    },

    SET_WINDOWS_WIDTH(state, { width }) {
      state.windowsWidth = width
    },

    SET_WINDOWS_HEIGHT(state, { height }) {
      state.windowsHeight = height
    },

    SET_ATTRIBUTE(state, { attribute, value }) {
      state[attribute] = value
    },

    SET_LAST_TIME_EMAIL_SENT(state, { lastTimeEmailSent }) {
      state.lastTimeEmailSent = lastTimeEmailSent
    },
  },

  actions: {
    ChangeMenuState({ state, commit }) {
      commit('SET_SHOW_MENU', !state.showMenu)
    },

    setWidth({ commit }, { width }) {
      commit('SET_WINDOWS_WIDTH', { width })
    },

    setHeight({ commit }, { height }) {
      commit('SET_WINDOWS_HEIGHT', { height })
    },

    setEmailSent({ commit }) {
      const lastTimeEmailSent = Date.now()
      commit('SET_LAST_TIME_EMAIL_SENT', { lastTimeEmailSent })
    },
  },
}

export default app
