import { axios } from '@/utils/request'
import config from '@/config'

export function login({ email, password }) {
  return axios({
    url: `${config.urlApi}/api/auth/login/`,
    method: 'post',
    data: { email, password },
  })
}

export function logout() {
  return axios({
    url: `${config.urlApi}/api/auth/logout/`,
    method: 'post',
  })
}

export function profile() {
  return axios({
    url: `${config.urlApi}/api/users/current_user/`,
    method: 'get',
  })
}

export function recovery({ email }) {
  return axios({
    url: `${config.urlApi}/api/users/user/recovery/${email}/`,
    method: 'post',
  })
}

export function changePassword({ code, data }) {
  return axios({
    url: `${config.urlApi}/api/users/user/change_password/${code}/`,
    method: 'post',
    data,
  })
}

export default {
  login,
  logout,
  profile,
  recovery,
  changePassword,
}
