export default {
  path: '',
  name: 'Landing',
  redirect: '/home',
  component: () => import('@/components/layouts/external/ExternalLayout.vue'),
  meta: {
    auth: false,
  },
  children: [
    {
      path: 'home',
      name: 'ExternalHome',
      component: () => import('@/views/external/home/Home.vue'),
      meta: {
        invalidateAuth: true,
        auth: false,
      },
    },
    {
      path: 'contact',
      name: 'Contact',
      component: () => import('@/views/external/contact/Contact.vue'),
      meta: {
        invalidateAuth: true,
        auth: false,
      },
    },
    {
      path: 'send-request',
      name: 'SendRequest',
      component: () => import('@/views/external/request/SendRequest.vue'),
      meta: {
        invalidateAuth: true,
        auth: false,
      },
    },
    {
      path: 'send-request-form',
      name: 'SendRequestForm',
      component: () => import('@/views/external/request/SendRequestForm.vue'),
      meta: {
        invalidateAuth: true,
        auth: false,
      },
    },
    {
      path: '/validar',
      name: 'Validar',
      component: () => import('@/views/external/validate/Validate.vue'),
      meta: {
        invalidateAuth: true,
        auth: false,
      },
    },
    {
      path: '/validate',
      name: 'Validate',
      component: () => import('@/views/external/validate/Validate.vue'),
      meta: {
        invalidateAuth: true,
        auth: false,
      },
    },
    {
      path: 'about-us',
      name: 'AboutUs',
      component: () => import('@/views/external/about_us/AboutUs.vue'),
      meta: {
        invalidateAuth: true,
        auth: false,
      },
    },
    {
      path: 'team',
      name: 'Team',
      component: () => import('@/views/external/team/Team.vue'),
      meta: {
        invalidateAuth: true,
        auth: false,
      },
    },
  ],
}
