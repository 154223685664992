import phoneCountryCodeServices from '@/services/phoneNumber/phoneCountryCode.service'

const countryCode = {
  namespaced: true,

  state: {
    phoneCountryCodes: [],
  },

  getters: {
    phoneCountryCodes: state => state.phoneCountryCodes,
  },

  mutations: {
    SET_COUNTRY_CODES: (state, values) => {
      state.phoneCountryCodes = values
    },

    CLEAR_STORE: state => {
      state.phoneCountryCodes = []
    },
  },

  actions: {
    GetPhoneCountryCodes({ commit, state }) {
      if (state.phoneCountryCodes.length > 0) {
        return new Promise(resolve => {
          resolve(this.state.phoneCountryCodes)
        })
      }

      return new Promise((resolve, reject) => {
        phoneCountryCodeServices
          .index()
          .then(response => {
            commit('SET_COUNTRY_CODES', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    ClearPhoneCountryCodes({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}

export default countryCode
