import { axios } from '@/utils/request'
import config from '@/config'

export function index() {
  return axios({
    url: `${config.urlApi}/api/demography/commune/`,
    method: 'get',
  })
}

export default {
  index,
}
