import { createApp } from 'vue'
import App from './App.vue'
import Vue3Storage, { StorageType } from 'vue3-storage'
// import VueSocialSharing from 'vue-social-sharing'
import { VueEditor } from 'vue3-editor'
import Vue3Toastify from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
// import { vMaska } from "maska"
// import Popper from "vue3-popper"
// import * as Sentry from '@sentry/vue'
import { init, BrowserTracing, Replay, vueRouterInstrumentation } from '@sentry/vue'

/** Plugins */
import vuetify from './plugins/vuetify'
import router from './router'
// import { loadFonts } from './plugins/webfontloader'
import store from './store'

/** Filters */
import { loadFilter } from './filters'

let app = createApp(App)
  .use(vuetify)
  .use(router)
  .use(store)
  .use(Vue3Storage, { namespace: 'bo_edu', storage: StorageType.Local })
  // .use(VueSocialSharing)
  .use(VueEditor)
  .use(Vue3Toastify, {
    autoClose: 3000,
  })
// .directive("maska", vMaska)

/** Components */
// app.component("Popper", Popper)

import './style/main.scss'

// loadFonts()
loadFilter(app)

// Sentry.
init({
  app,
  dsn: 'https://474a5d27300841f2a3ae639ad4ecf793@o4505464008212480.ingest.sentry.io/4505464055660544',
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: vueRouterInstrumentation(router),
    }),
    new Replay(),
  ],
  tracesSampleRate: 0.4,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
})

app.mount('#app')
