/** External Dependencies */
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

/** Routes */
import internalRoute from '@/router/routes/internal.route'
import externalRoute from '@/router/routes/external.route'

const routes = [
  {
    path: '',
    name: 'ExternalLayout',
    redirect: '/home',
    component: () => import('@/components/layouts/external/ExternalLayout.vue'),
    meta: {
      auth: false,
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
          invalidateAuth: false,
          auth: false,
        },
      },

      {
        path: 'recovery',
        name: 'Recovery',
        component: () => import('@/views/auth/Recovery.vue'),
        meta: {
          invalidateAuth: true,
          auth: false,
        },
      },

      {
        path: 'change_password',
        name: 'ChangePassword',
        component: () => import('@/views/auth/ChangePassword.vue'),
        meta: {
          invalidateAuth: true,
          auth: false,
        },
      },
    ],
  },

  {
    path: '/verify_email',
    name: 'VerifyEmail',
    component: () => import('@/views/auth/VerifyEmail.vue'),
    meta: {
      invalidateAuth: true,
      auth: false,
    },
  },

  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      invalidateAuth: true,
      auth: false,
    },
  },

  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/auth/Logout.vue'),
    meta: {
      invalidateAuth: false,
      auth: false,
    },
  },

  /** External Routes */
  externalRoute,

  /** Internal Routes */
  internalRoute,

  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    redirect: '/portal/home',
  },
]

const router = new createRouter({
  history: createWebHistory(),
  routes,
})

function isAuthenticated() {
  return store.getters['auth/isAuthenticated']
}

function authRequired(to) {
  return to.meta.auth
}

function invalidateAuth(to) {
  return to.meta.invalidateAuth
}

function initialActions() {
  store.dispatch('auth/GetProfile')
  store.dispatch('commune/GetCommunes')
  store.dispatch('phoneCountryCode/GetPhoneCountryCodes')
}

router.beforeEach((to, from, next) => {
  if (to.name === 'ExternalHome' && isAuthenticated()) {
    // Avoid redirect to landing if user is authenticated
    next('/portal')
  }

  if (authRequired(to) && !isAuthenticated()) {
    next('/')
  } else if (authRequired(to) && isAuthenticated()) {
    initialActions()
    next()
  } else if (!authRequired(to) && !invalidateAuth(to) && isAuthenticated()) {
    initialActions()
    next('/portal')
  } else if (!authRequired(to) && invalidateAuth(to) && isAuthenticated()) {
    initialActions()
    next()
  } else {
    if (isAuthenticated()) initialActions()
    next()
  }

  // next()
})

export default router
