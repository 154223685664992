// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: '#0169E2',
    secondary: '#75A5FF',
    thirty: '#94B2FF',
    fourth: '#F2F4FF',
    success: '#78B66B',
    grey1: '#333333',
    grey2: '#666666',
    grey3: '#C6C6C5',
    grey4: '#EEEEEE',
    black: '#000',
    white: '#FFFFFF',
    white2: '#F6F6F6',
    red: '#d94755',
    green: '#78b66b',
    yellow: '#FFEEB4',
    typo: '#252525',
    checkbox: '#1890ff',
    // background: '#FFFFFF',
    // surface: '#FFFFFF',
    // 'primary-darken-1': '#3700B3',
    // secondary: '#03DAC6',
    // 'secondary-darken-1': '#018786',
    // error: '#B00020',
    // info: '#2196F3',
    // success: '#4CAF50',
    // warning: '#FB8C00',
  },
}

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
})
