// Externals dependencies
import router from '@/router'
import { useStorage } from 'vue3-storage'
import config from '@/config'

// Services
import authServices from '@/services/auth/auth.services'

// Constants
import { AUTH_KEY } from '@/constants/auth.constants'

const auth = {
  namespaced: true,

  state: {
    sentEmailTime: null,
    lastApiCallTime: null,
    isAuthenticated: false,

    /** User Auth */
    user: {
      role: null,
    },
    email: {},
    role: null,
    link: null,

    /** Auth Credentials */
    key: null,
  },

  getters: {
    isAuthenticated: state => state.isAuthenticated,

    /** User Auth */
    user: state => state.user,
    email: state => state.email,
    role: state => state.role,
    link: state => state.link,

    /** Auth Credentials */
    key: state => state.key,
    sentEmailTime: state => state.sentEmailTime,
  },

  mutations: {
    SET_SENT_EMAIL_TIME: (state, timestamp) => {
      state.sentEmailTime = timestamp
    },

    SET_LAST_API_CALL_TIME: (state, lastTime) => {
      state.lastApiCallTime = lastTime
    },

    SET_TOKEN: (state, { key }) => {
      state.key = key
    },

    SET_IS_AUTHENTICATE: (state, isAuthenticated) => {
      state.isAuthenticated = isAuthenticated
    },

    SET_USER: (state, user) => {
      state.user = user
      state.role = user.role === 0 ? 'doctor' : 'patient'
      state.link = `${config.selfUrl}/send-request?code=${user.code}`
    },

    CLEAR_STORE: state => {
      state.user = {
        role: null,
      }
      state.isAuthenticated = false
      /** User Auth */
      state.email = null
      state.role = null
      state.link = null

      /** Auth Credentials */
      state.key = null
    },
  },

  actions: {
    SignIn({ commit, dispatch }, { email, password }) {
      return new Promise((resolve, reject) => {
        authServices
          .login({ email, password })
          .then(response => {
            const { key } = response.data

            /** Save token */
            const storage = useStorage()
            storage.setStorageSync(AUTH_KEY, key)

            localStorage.setItem(AUTH_KEY, key)
            commit('SET_TOKEN', { key })
            commit('SET_IS_AUTHENTICATE', true)

            /** Save User */
            // commit('SET_USER', data)
            dispatch('GetProfile').finally(() => {
              resolve(true)
            })
          })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
              reject(error.response.data.errors)
            } else {
              reject(error)
            }
          })
      })
    },

    GetProfile({ dispatch, commit, state }) {
      // Avoid multiple calls in less than 1 second (returns the last user)
      const currentTime = Date.now()
      if (state.lastApiCallTime && currentTime - state.lastApiCallTime < 1000) {
        return new Promise(resolve => {
          resolve(state.user)
          commit('SET_LAST_API_CALL_TIME', currentTime)
        })
      }

      return new Promise((resolve, reject) => {
        authServices
          .profile()
          .then(response => {
            commit('SET_USER', response.data)
            commit('SET_LAST_API_CALL_TIME', Date.now())
            resolve(response.data)
          })
          .catch(error => {
            dispatch('SignOut').finally(() => {
              router.push({ name: 'Login' })
            })
            reject(error)
          })
      })
    },

    SignOut({ commit }) {
      return new Promise(resolve => {
        /** Limpiar cache de apollo */
        authServices.logout().finally(() => {
          /** Remover vue local storage */
          const storage = useStorage()
          storage.clearStorageSync()

          /** Remover local storage */
          localStorage.clear()

          /** Limpiar toda la store */
          commit('CLEAR_STORE')
          resolve(true)
        })
      })
    },

    RegisterUser({ commit }, data) {
      return new Promise(resolve => {
        commit('SET_USER', data)
        resolve(true)
      })
    },
  },
}

export default auth
