import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

/** Modules */
import app from '@/store/modules/app'
import request from '@/store/modules/request'

/** Auth */
import auth from '@/store/modules/auth'

/** Demography */
import commune from '@/store/modules/commune'

/** Country Code */
import phoneCountryCode from '@/store/modules/phoneCountryCode'

const modules = {
  app,
  request,

  /** Auth */
  auth,

  /** Demography */
  commune,

  /** Country Code */
  phoneCountryCode,
}

export default new createStore({
  state: {
    profile: {},
  },
  mutations: {},
  actions: {
    clearStores({ commit }) {
      Object.keys(modules).forEach(module => {
        commit(`${module}/CLEAR_STORE`)
      })
    },
  },
  modules,
  plugins: [
    createPersistedState({
      reducer: persistedState => {
        return JSON.parse(JSON.stringify(persistedState))
      },
    }),
  ],
})
